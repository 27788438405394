export default {
        state: () => [],
        getters: {
                getCourseVideos: (state) => (courseId) => {
                        for (const playlist of state) {
                                if (playlist.courseId == courseId) {
                                        return playlist.videos
                                }
                        }
                        return []
                },
                getCourseState: (state) => (courseId, type) => {
                        for (const playlist of state) {
                                if (
                                        playlist.courseId == courseId &&
                                        playlist.type == type
                                ) {
                                        return playlist
                                }
                        }
                        return null
                },
        },
        mutations: {
                setPlaylist(state, { videos, courseId, type }) {
                        state.push({ videos, courseId, type })
                },
                updatePlaylist(state, { videos, courseId }) {
                        for (const playlist of state) {
                                if (playlist.courseId == courseId) {
                                        playlist.videos = videos
                                }
                        }
                },
        },
        actions: {},
}
