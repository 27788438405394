import gql from 'graphql-tag'
export const typeDefs = gql`
        type Author {
                _id: ID
                basicId: Int
                userId: ID
                firstname: String
                lastname: String
                label: String
                job: String
                bio: String
                website: String
                twitter: String
                facebook: String
                instagram: String
                email: String
                photo: String
                altPhoto: String
                secret: String
                createdAt: Int
                updatedAt: Int
                isPublic: Int
                courseId: [ID]
                profileUrl: String
                timeWatched: Int
                courses: [Course]
        }

        type BoComment {
                _id: ID
                adminId: ID
                userId: ID
                comment: String
                createdAt: Date
                updatedOn: Date
                admin: User
                user: User
        }

        type ChapterItems {
                _id: ID
                title: String
                courseId: ID
                parentId: ID
                chapterId: ID
                duration: Int
                index: Int
                link: String
                mediaId: String
                children: [ChapterItems]
                typeName: String
                ressource: [JSONObject]
                subvideos: [JSONObject]
                isBonus: Boolean
                progress(courseInfos: String!): Int
                completed(courseInfos: String!): Boolean
        }

        type Course {
                all(
                        _id: ID
                        basicId: Int
                        shootingDate: String
                        status: String
                        duration: Int
                        createdAt: Date
                        updatedAt: Date
                        publishedAt: Date
                        isPublic: Int
                        jwplaylistId: String
                        sort: JSONObject
                        page: Int
                        pageLength: Int
                        filters: JSONObject
                        isTopCourse: Boolean
                        topCoursePosition: Int
                ): [Course]
                allProgression: JSONObject
                allUserAutoEvaluationMark: JSONObject
                _id: ID
                basicId: Int
                title: String
                url: String
                newUrl: String
                image: String
                resume: String
                description: String
                marketingName: String
                teaser: String
                clip: String
                shootingDate: String
                priceBeforeLive: Int
                priceAfterLive: Int
                discountPrice: Int
                discountPriceExpiration: Int
                googleTitle: String
                googleDescription: String
                facebookTitle: String
                facebookDescription: String
                surveyMonkey: String
                rsvpImage: String
                status: String
                isPublic: Int
                assemblyEndDate: Int
                jwplaylistId: String
                createdAt: Date
                updatedAt: Date
                publishedAt: Date
                socialSharing: JSONObject
                liveQuestionNb: Int
                rawSummary: String
                summary: [JSONObject]
                ressource: [JSONObject]
                duration: Int
                timeWatched: Int
                mark: Float
                markCount: Int
                isOpen: Boolean
                videoMediaIds: [String]
                kinescopeId: String
                content: String
                learnResume: String
                surveyPrelive: String
                ctaSurvey: String
                urlSurvey: String
                forWho: String
                learnObjective: String
                bonusCourse: String
                prerequisiteDesc: String
                comeToLive: String
                ctaComeToLive: String
                urlComeToLive: String
                isTopCourse: Boolean
                topCoursePosition: Int
                staticPlaylistUrl: String
                authors: [Author]
                live: [Live]
                disponibility: String
                embref: Course
                fields: CourseCategories
                chapters: [CourseChapter]
                videos: [CourseVideo]
                prerequisite: [Course]
                startDate: Date
                progress(courseInfos: String!): Int
                completed(courseInfos: String!): Boolean
                progression: Int
                studentCount: Int
                students: [User]
                userAutoEvaluationMark: Int
                userAutoEvaluationQuestions: JSONObject
                SetupCourseWatchTimesWithKinescope: JSONObject
        }

        type CourseCategories {
                category: Field
                field: [Field]
                subfield: [Field]
        }

        type CourseChapter {
                _id: ID
                title: String
                index: Int
                courseId: ID
                parentId: ID
                typeName: String
                ressource: [JSONObject]
                isBonus: Boolean
                course: Course
                parent: CourseChapter
                children: [ChapterItems]
                videos: [CourseVideo]
                ressources: [JSONObject]
                progress(courseInfos: String!): Int
                completed(courseInfos: String!): Boolean
                totalProgression: Int
                studentCount: Int
        }

        type CoursePosition {
                course: Course
                position: Int
        }

        type CourseVideo {
                _id: ID
                id: Int
                title: String
                link: String
                duration: Int
                mediaId: String
                jwplayerId: String
                index: Int
                chapterId: ID
                courseId: ID
                parentId: ID
                typeName: String
                ressource: [JSONObject]
                subvideos: [JSONObject]
                downloadLink: String
                kinescopeId: String
                kinescopeFolderId: String
                splits: [JSONObject]
                additionalMaterials: [JSONObject]
                privacyDomains: [JSONObject]
                platform: String
                chapter: CourseChapter
                course: Course
                parent: CourseVideo
                progress(courseInfos: String!): Int
                completed(courseInfos: String!): Boolean
        }

        type Cron {
                _id: ID
                name: String
                description: String
                tag: String
                running: Boolean
                query: String
                schedule: String
                convertedSchedule: String
                createdAt: Date
                updatedAt: Date
        }

        scalar Date

        type Feedback {
                _id: ID
                basicId: Int
                courseId: ID
                userId: ID
                mark: Int
                content: String
                createdAt: Int
                updatedAt: Int
        }

        type Field {
                _id: ID
                name: String
                image: String
                altImage: String
                description: String
                level: Int
                position: Int
                children: [Field]
                coursePositions: [JSONObject]
                coursePos: [CoursePosition]
        }

        type Gotw {
                _id: ID
                userId: ID
                goal: Int
                progress: Int
                createdAt: Date
                duration: Int
        }

        type Invoice {
                reference: String
                _id: ID
                platform: String
                stripe: JSONObject
                paypal: JSONObject
                alma: JSONObject
                createdAt: Date
                updatedAt: Date
                user: User
        }

        scalar JSONObject

        type Live {
                _id: ID
                url: String
                courseId: Int
                startDate: Date
                endDate: Date
                nQFreeUsers: Int
                status: String
                promoCode: String
                promoAmount: Int
                promoExp: Date
                postFb: String
                idYtCreatorStudio: String
                hotjarScript: String
                customerioScript: String
                updatedAt: Date
                stats: JSONObject
                subOnly: Boolean
                linkedCourses: [Course]
                course: Course
        }

        type LiveStats {
                _id: ID
                courseId: ID
                dateStart: Int
                dateEnd: Int
                data: JSONObject
        }

        type Mutation {
                UploadToKinescope(playlist: JSONObject): Boolean
                UploadToJwplayer(playlist: JSONObject): Boolean
                CreateCourse(
                        _id: ID
                        basicId: Int
                        title: String
                        url: String
                        newUrl: String
                        image: String
                        resume: String
                        description: String
                        marketingName: String
                        teaser: String
                        clip: String
                        shootingDate: String
                        priceBeforeLive: Int
                        priceAfterLive: Int
                        discountPrice: Int
                        discountPriceExpiration: Int
                        googleTitle: String
                        googleDescription: String
                        facebookTitle: String
                        facebookDescription: String
                        surveyMonkey: String
                        rsvpImage: String
                        status: String
                        isPublic: Int
                        assemblyEndDate: Int
                        jwplaylistId: String
                        createdAt: Date
                        updatedAt: Date
                        publishedAt: Date
                        socialSharing: JSONObject
                        liveQuestionNb: Int
                        rawSummary: String
                        summary: [JSONObject]
                        ressource: [JSONObject]
                        duration: Int
                        timeWatched: Int
                        mark: Float
                        markCount: Int
                        isOpen: Boolean
                        videoMediaIds: [String]
                        kinescopeId: String
                        content: String
                        learnResume: String
                        surveyPrelive: String
                        ctaSurvey: String
                        urlSurvey: String
                        forWho: String
                        learnObjective: String
                        bonusCourse: String
                        prerequisiteDesc: String
                        comeToLive: String
                        ctaComeToLive: String
                        urlComeToLive: String
                        isTopCourse: Boolean
                        topCoursePosition: Int
                        staticPlaylistUrl: String
                        imageName: String
                        authors: [ID]
                        fields: JSONObject
                        live: [ID]
                        prerequisite: [ID]
                ): Course
                UpdateCourse(
                        _id: ID
                        basicId: Int
                        title: String
                        url: String
                        newUrl: String
                        image: String
                        resume: String
                        description: String
                        marketingName: String
                        teaser: String
                        clip: String
                        shootingDate: String
                        priceBeforeLive: Int
                        priceAfterLive: Int
                        discountPrice: Int
                        discountPriceExpiration: Int
                        googleTitle: String
                        googleDescription: String
                        facebookTitle: String
                        facebookDescription: String
                        surveyMonkey: String
                        rsvpImage: String
                        status: String
                        isPublic: Int
                        assemblyEndDate: Int
                        jwplaylistId: String
                        createdAt: Date
                        updatedAt: Date
                        publishedAt: Date
                        socialSharing: JSONObject
                        liveQuestionNb: Int
                        rawSummary: String
                        summary: [JSONObject]
                        ressource: [JSONObject]
                        duration: Int
                        timeWatched: Int
                        mark: Float
                        markCount: Int
                        isOpen: Boolean
                        videoMediaIds: [String]
                        kinescopeId: String
                        content: String
                        learnResume: String
                        surveyPrelive: String
                        ctaSurvey: String
                        urlSurvey: String
                        forWho: String
                        learnObjective: String
                        bonusCourse: String
                        prerequisiteDesc: String
                        comeToLive: String
                        ctaComeToLive: String
                        urlComeToLive: String
                        isTopCourse: Boolean
                        topCoursePosition: Int
                        staticPlaylistUrl: String
                        imageName: String
                        authors: [ID]
                        fields: JSONObject
                        live: [ID]
                        prerequisite: [ID]
                ): Boolean
                DeleteCourse(
                        _id: ID
                        basicId: Int!
                        deleteLive: Boolean
                        liveId: ID
                ): Boolean
                UpdateCourseTimeWatched: Boolean
                UpdateCourseMark: Boolean
                UpdateTopCourses(topCourses: [JSONObject]): Boolean
                CreateCourseChapter(
                        _id: ID
                        title: String
                        index: Int
                        courseId: ID
                        parentId: ID
                        typeName: String
                        ressource: [JSONObject]
                        isBonus: Boolean
                ): CourseChapter
                UpdateCourseChapter(
                        _id: ID
                        title: String
                        index: Int
                        courseId: ID
                        parentId: ID
                        typeName: String
                        ressource: [JSONObject]
                        isBonus: Boolean
                ): Boolean
                UpdateMultiCourseChapter(
                        chapters: [JSONObject]
                        courseId: String
                ): [CourseVideo]
                DeleteCourseChapter(chapter: JSONObject): Boolean
                CreateReservation(
                        _id: ID
                        courseId: ID
                        userId: ID
                        createdAt: Date
                        updatedOn: Date
                ): Reservation
                UpdateReservation(
                        _id: ID
                        courseId: ID
                        userId: ID
                        createdAt: Date
                        updatedOn: Date
                ): Boolean
                DeleteReservation(_id: ID): Boolean
                CreateCourseVideo(
                        _id: ID
                        id: Int
                        title: String
                        link: String
                        duration: Int
                        mediaId: String
                        jwplayerId: String
                        index: Int
                        chapterId: ID
                        courseId: ID
                        parentId: ID
                        typeName: String
                        ressource: [JSONObject]
                        subvideos: [JSONObject]
                        downloadLink: String
                        kinescopeId: String
                        kinescopeFolderId: String
                        splits: [JSONObject]
                        additionalMaterials: [JSONObject]
                        privacyDomains: [JSONObject]
                        platform: String
                ): CourseVideo
                UpdateCourseVideo(
                        _id: ID
                        id: Int
                        title: String
                        link: String
                        duration: Int
                        mediaId: String
                        jwplayerId: String
                        index: Int
                        chapterId: ID
                        courseId: ID
                        parentId: ID
                        typeName: String
                        ressource: [JSONObject]
                        subvideos: [JSONObject]
                        downloadLink: String
                        kinescopeId: String
                        kinescopeFolderId: String
                        splits: [JSONObject]
                        additionalMaterials: [JSONObject]
                        privacyDomains: [JSONObject]
                        platform: String
                ): Boolean
                DeleteCourseVideo(_id: ID): Boolean
                UpsertCourseVideos(chapter: JSONObject): [CourseVideo]
                CreateLive(
                        _id: ID
                        url: String
                        courseId: Int
                        startDate: Date
                        endDate: Date
                        nQFreeUsers: Int
                        status: String
                        promoCode: String
                        promoAmount: Int
                        promoExp: Date
                        postFb: String
                        idYtCreatorStudio: String
                        hotjarScript: String
                        customerioScript: String
                        updatedAt: Date
                        stats: JSONObject
                        subOnly: Boolean
                        linkedCourses: [ID]
                        course: ID
                ): Live
                UpdateLive(
                        _id: ID
                        url: String
                        courseId: Int
                        startDate: Date
                        endDate: Date
                        nQFreeUsers: Int
                        status: String
                        promoCode: String
                        promoAmount: Int
                        promoExp: Date
                        postFb: String
                        idYtCreatorStudio: String
                        hotjarScript: String
                        customerioScript: String
                        updatedAt: Date
                        stats: JSONObject
                        subOnly: Boolean
                        linkedCourses: [ID]
                        course: ID
                ): Live
                DeleteLive(_id: ID): Boolean
                CreateAuthor(
                        _id: ID
                        basicId: Int
                        userId: ID
                        firstname: String
                        lastname: String
                        label: String
                        job: String
                        bio: String
                        website: String
                        twitter: String
                        facebook: String
                        instagram: String
                        email: String
                        photo: String
                        altPhoto: String
                        secret: String
                        createdAt: Int
                        updatedAt: Int
                        isPublic: Int
                        courseId: [ID]
                        profileUrl: String
                        timeWatched: Int
                        photoName: String
                ): Author
                UpdateAuthor(
                        _id: ID
                        basicId: Int
                        userId: ID
                        firstname: String
                        lastname: String
                        label: String
                        job: String
                        bio: String
                        website: String
                        twitter: String
                        facebook: String
                        instagram: String
                        email: String
                        photo: String
                        altPhoto: String
                        secret: String
                        createdAt: Int
                        updatedAt: Int
                        isPublic: Int
                        courseId: [ID]
                        profileUrl: String
                        timeWatched: Int
                        photoName: String
                ): Boolean
                DeleteAuthor(_id: ID, basicId: Int!, email: String): Boolean
                UpdateAuthorTimeWatched: Boolean
                CreateQuestion(
                        _id: ID
                        basicId: Int
                        userId: ID
                        courseId: ID
                        question: String
                        likes: Int
                        answered: Boolean
                        tag: String
                        createdAt: Int
                ): Question
                UpdateQuestion(
                        _id: ID
                        basicId: Int
                        userId: ID
                        courseId: ID
                        question: String
                        likes: Int
                        answered: Boolean
                        tag: String
                        createdAt: Int
                ): Boolean
                DeleteQuestion(_id: ID, basicId: Int): Boolean
                CreateLiveStats(
                        _id: ID
                        courseId: ID
                        dateStart: Int
                        dateEnd: Int
                        data: JSONObject
                ): LiveStats
                UpdateLiveStats(
                        _id: ID
                        courseId: ID
                        dateStart: Int
                        dateEnd: Int
                        data: JSONObject
                ): Boolean
                DeleteLiveStats(_id: ID): Boolean
                CreateFeedback(
                        _id: ID
                        basicId: Int
                        courseId: ID
                        userId: ID
                        mark: Int
                        content: String
                        createdAt: Int
                        updatedAt: Int
                        url: String
                ): Feedback
                UpdateFeedback(
                        _id: ID
                        basicId: Int
                        courseId: ID
                        userId: ID
                        mark: Int
                        content: String
                        createdAt: Int
                        updatedAt: Int
                ): Boolean
                DeleteFeedback(_id: ID, basicId: Int): Boolean
                CreateField(
                        _id: ID
                        name: String
                        image: String
                        altImage: String
                        description: String
                        level: Int
                        position: Int
                        positionsList: [JSONObject]
                        children: [ID]
                        coursePositions: [JSONObject]
                        imageName: String
                ): Field
                UpdateField(
                        _id: ID
                        name: String
                        image: String
                        altImage: String
                        description: String
                        level: Int
                        position: Int
                        positionsList: [JSONObject]
                        children: [ID]
                        coursePositions: [JSONObject]
                        imageName: String
                ): Boolean
                UpdateFieldsPosition(
                        _id: ID
                        name: String
                        image: String
                        altImage: String
                        description: String
                        level: Int
                        position: Int
                        positionsList: [JSONObject]
                        children: [ID]
                        coursePositions: [JSONObject]
                        imageName: String
                ): Boolean
                UpdateCoursePosByFields(courses: [JSONObject]): Boolean
                UpdateOneCoursePosByFields(course: JSONObject): Boolean
                DeleteField(
                        _id: ID
                        name: String
                        image: String
                        altImage: String
                        description: String
                        level: Int
                        position: Int
                        positionsList: [JSONObject]
                        children: [ID]
                        coursePositions: [JSONObject]
                ): Boolean
                CreateUserVideoProgression(
                        _id: ID
                        userId: ID
                        videoId: ID
                        progress: Int
                        virtualProgress: Int
                        completed: Boolean
                        lastPosition: Int
                        timeSpent: Int
                        updatedAt: Date
                ): UserVideoProgression
                UpdateUserVideoProgression(
                        _id: ID
                        userId: ID
                        videoId: ID
                        progress: Int
                        virtualProgress: Int
                        completed: Boolean
                        lastPosition: Int
                        timeSpent: Int
                        updatedAt: Date
                ): Boolean
                DeleteUserVideoProgression(_id: ID): Boolean
                CreateUserChapterProgression(
                        _id: ID
                        userId: ID
                        chapterId: ID
                        progress: Int
                        virtualProgress: Int
                        completed: Boolean
                ): UserChapterProgression
                UpdateUserChapterProgression(
                        _id: ID
                        userId: ID
                        chapterId: ID
                        progress: Int
                        virtualProgress: Int
                        completed: Boolean
                ): Boolean
                DeleteUserChapterProgression(_id: ID): Boolean
                CreateUserCourseProgression(
                        _id: ID
                        userId: ID
                        courseId: ID
                        progress: Int
                        virtualProgress: Int
                        completed: Boolean
                        updatedAt: Date
                ): UserCourseProgression
                UpdateUserCourseProgression(
                        _id: ID
                        userId: ID
                        courseId: ID
                        progress: Int
                        virtualProgress: Int
                        completed: Boolean
                        updatedAt: Date
                ): Boolean
                DeleteUserCourseProgression(_id: ID): Boolean
                CreateGotw(
                        _id: ID
                        userId: ID
                        goal: Int
                        progress: Int
                        createdAt: Date
                        duration: Int
                ): Gotw
                UpdateGotw(
                        _id: ID
                        userId: ID
                        goal: Int
                        progress: Int
                        createdAt: Date
                        duration: Int
                ): Boolean
                DeleteGotw(_id: ID!): Boolean
                CreateUserProFormation(
                        _id: ID
                        userId: ID
                        courseId: ID
                        status: String
                ): JSONObject
                UpdateUserProFormation(
                        _id: ID
                        userId: ID
                        courseId: ID
                        status: String
                ): Boolean
                DeleteUserProFormation(_id: ID!): Boolean
                SetupUserStats: ProgressionSetter
                UpdateProgression(
                        userId: ID!
                        videoId: ID
                        chapterId: ID
                        courseId: ID!
                        progress: Int
                        lastPosition: Int
                        timeSpent: Int
                        completed: Boolean
                        appProgress: Boolean
                ): String
                SetTotalProgressionsDaily: Boolean
                AskAvis1: Boolean
                AskAvis2: Boolean
                AutoEvaluation: Boolean
                ComeBack: Boolean
                FinishCourse: Boolean
                DidLeave: Boolean
                CreateCron(
                        _id: ID
                        name: String
                        description: String
                        tag: String
                        running: Boolean
                        query: String
                        schedule: String
                        convertedSchedule: String
                        createdAt: Date
                        updatedAt: Date
                ): Cron
                UpdateCron(
                        _id: ID
                        name: String
                        description: String
                        tag: String
                        running: Boolean
                        query: String
                        schedule: String
                        convertedSchedule: String
                        createdAt: Date
                        updatedAt: Date
                ): Cron
                DeleteCron(_id: ID!): Boolean
                CreateScript(
                        _id: ID
                        name: String
                        description: String
                        tag: String
                        query: String
                        createdAt: Date
                        updatedAt: Date
                ): Script
                UpdateScript(
                        _id: ID
                        name: String
                        description: String
                        tag: String
                        query: String
                        createdAt: Date
                        updatedAt: Date
                ): Script
                DeleteScript(_id: ID!): Boolean
                CreateWebhook(
                        _id: ID
                        type: String
                        user: ID
                        course: ID
                        byDefault: Boolean
                        createdAt: Date
                        updatedAt: Date
                ): Webhook
                UpdateWebhook(
                        _id: ID
                        type: String
                        user: ID
                        course: ID
                        byDefault: Boolean
                        createdAt: Date
                        updatedAt: Date
                ): Webhook
                DeleteWebhook(_id: ID!): Boolean
                Watching(
                        browserId: String
                        browserName: String
                        os: String
                        userAgent: String
                        screenResolution: String
                ): JSONObject
                iamWatching(
                        browserId: String
                        browserName: String
                        os: String
                        userAgent: String
                        screenResolution: String
                ): JSONObject
                Login(
                        email: String!
                        password: String!
                        bo: Boolean
                        fromLive: Boolean
                        alreadyMD5: Boolean
                ): Token
                LoginGoogle(
                        token: String
                        fromLive: Boolean
                        email: String
                ): Token
                CreateUser(
                        basicId: Int
                        firstname: String
                        lastname: String
                        email: String
                        password: String
                        username: String
                        utmSource: String
                        utmMedium: String
                        utmContent: String
                        utmCampaign: String
                        utmTerm: String
                        isActivated: Boolean
                        cio: Boolean
                        isGoogle: Boolean
                        isTemporary: Boolean
                ): UserStatus
                CreateUserGoogle(token: String!): Token
                UpdateUser(
                        _id: ID
                        basicId: Int
                        email: String
                        firstname: String
                        lastname: String
                        password: String
                        refreshToken: String
                        activationToken: String
                        isActivated: Boolean
                        username: String
                        profilePicture: String
                        isGoogle: Boolean
                        isTemporary: Boolean
                        hasTrialed: Int
                        stripe: String
                        rememberCard: Int
                        tva: String
                        companyName: String
                        timezone: String
                        adresse: String
                        zipcode: String
                        city: String
                        country: String
                        birthday: String
                        liveLocalisation: String
                        shareLocalisation: Int
                        googleId: String
                        facebookId: String
                        unsubscribeId: String
                        referrerId: Int
                        referralCreation: Int
                        sharingLink: String
                        totalGOTW: Int
                        currentGOTW: Int
                        createdAt: Date
                        updatedOn: Date
                        lastLogin: Date
                        lastVisit: Date
                        roles: [String]
                ): Boolean
                UpdateUserOld(
                        oldEmail: String
                        _id: ID
                        basicId: Int
                        email: String
                        firstname: String
                        lastname: String
                        password: String
                        refreshToken: String
                        activationToken: String
                        isActivated: Boolean
                        username: String
                        profilePicture: String
                        isGoogle: Boolean
                        isTemporary: Boolean
                        hasTrialed: Int
                        stripe: String
                        rememberCard: Int
                        tva: String
                        companyName: String
                        timezone: String
                        adresse: String
                        zipcode: String
                        city: String
                        country: String
                        birthday: String
                        liveLocalisation: String
                        shareLocalisation: Int
                        googleId: String
                        facebookId: String
                        unsubscribeId: String
                        referrerId: Int
                        referralCreation: Int
                        sharingLink: String
                        totalGOTW: Int
                        currentGOTW: Int
                        createdAt: Date
                        updatedOn: Date
                        lastLogin: Date
                        lastVisit: Date
                ): Boolean
                UpdateUserPassword(
                        _id: ID
                        email: String
                        oldPassword: String!
                        newPassword: String!
                ): Boolean
                DeleteUser(_id: ID, basicId: Int, email: String): Boolean
                ActivateAccount(activationToken: String!): UserStatus
                Subscribe(email: String!, role: String): UserStatus
                UpdateAvatarOld(
                        email: String!
                        fileName: String!
                        base64image: String!
                ): Boolean
                CancelExpiredSubscriptions: Boolean
                CreateUserImage(
                        _id: ID
                        loginUrl: String
                        createUrl: String
                ): UserImage
                UpdateUserImage(
                        _id: ID
                        loginUrl: String
                        createUrl: String
                ): Boolean
                DeleteUserImage(
                        _id: ID
                        loginUrl: String
                        createUrl: String
                ): Boolean
                AllEmailsToLowerCase: Boolean
                CreateSubscription(
                        _id: ID
                        basicId: Int
                        userId: Int
                        planId: Int
                        multiPaymentId: Int
                        planType: String
                        stripe: JSONObject
                        oneShot: Boolean
                        paypal: JSONObject
                        stripeId: String
                        mobileReceipt: String
                        status: String
                        name: String
                        isActive: Int
                        isTrial: Int
                        isUnpaid: Int
                        isPending: Int
                        isWaitingunsub: Int
                        nbFreeDownloaded: Int
                        currentPeriodStart: Date
                        currentPeriodEnd: Date
                        canceledAt: Date
                        createdAt: Date
                        endedAt: Date
                        updatedAt: Date
                        raisonResiliation: String
                        subType: String
                        platform: String
                ): Subscription
                UpdateSubscription(
                        _id: ID
                        basicId: Int
                        userId: Int
                        planId: Int
                        multiPaymentId: Int
                        planType: String
                        stripe: JSONObject
                        oneShot: Boolean
                        paypal: JSONObject
                        stripeId: String
                        mobileReceipt: String
                        status: String
                        name: String
                        isActive: Int
                        isTrial: Int
                        isUnpaid: Int
                        isPending: Int
                        isWaitingunsub: Int
                        nbFreeDownloaded: Int
                        currentPeriodStart: Date
                        currentPeriodEnd: Date
                        canceledAt: Date
                        createdAt: Date
                        endedAt: Date
                        updatedAt: Date
                        raisonResiliation: String
                        subType: String
                        platform: String
                ): Boolean
                DeleteSubscription(
                        _id: ID
                        basicId: Int
                        userId: Int
                        planId: Int
                        multiPaymentId: Int
                        planType: String
                        stripe: JSONObject
                        oneShot: Boolean
                        paypal: JSONObject
                        stripeId: String
                        mobileReceipt: String
                        status: String
                        name: String
                        isActive: Int
                        isTrial: Int
                        isUnpaid: Int
                        isPending: Int
                        isWaitingunsub: Int
                        nbFreeDownloaded: Int
                        currentPeriodStart: Date
                        currentPeriodEnd: Date
                        canceledAt: Date
                        createdAt: Date
                        endedAt: Date
                        updatedAt: Date
                        raisonResiliation: String
                        subType: String
                        platform: String
                ): Boolean
                CreateReceipt(
                        _id: ID
                        basicId: Int
                        userId: Int
                        fullname: String
                        reference: String
                        companyName: String
                        tvaIdentification: String
                        address1: String
                        address2: String
                        zipCode: String
                        city: String
                        paidAt: Date
                        country: String
                        amount: Int
                        user: ID
                ): Receipt
                UpdateReceipt(
                        _id: ID
                        basicId: Int
                        userId: Int
                        fullname: String
                        reference: String
                        companyName: String
                        tvaIdentification: String
                        address1: String
                        address2: String
                        zipCode: String
                        city: String
                        paidAt: Date
                        country: String
                        amount: Int
                        user: ID
                ): Boolean
                DeleteReceipt(
                        _id: ID
                        basicId: Int
                        userId: Int
                        fullname: String
                        reference: String
                        companyName: String
                        tvaIdentification: String
                        address1: String
                        address2: String
                        zipCode: String
                        city: String
                        paidAt: Date
                        country: String
                        amount: Int
                        user: ID
                ): Boolean
                CreateStripeInvoice(data: JSONObject!): String
                RefundStripeInvoice(data: JSONObject!): String
                CancelPaypalSubscription(data: JSONObject!): Boolean
                CreateBoComment(
                        _id: ID
                        adminId: ID
                        userId: ID
                        comment: String
                        createdAt: Date
                        updatedOn: Date
                ): BoComment
                UpdateBoComment(
                        _id: ID
                        adminId: ID
                        userId: ID
                        comment: String
                        createdAt: Date
                        updatedOn: Date
                ): Boolean
                DeleteBoComment(
                        _id: ID
                        adminId: ID
                        userId: ID
                        comment: String
                        createdAt: Date
                        updatedOn: Date
                ): Boolean
                CreateUserCourse(
                        user: ID
                        course: ID
                        _id: ID
                        basicId: Int
                        userId: Int
                        liveId: Int
                        subscriptionId: ID
                        admin: ID
                        contributor: Int
                        createdAt: Date
                        updatedAt: Date
                ): UserCourse
                UpdateUserCourse(
                        course: ID
                        user: ID
                        _id: ID
                        basicId: Int
                        userId: Int
                        liveId: Int
                        subscriptionId: ID
                        admin: ID
                        contributor: Int
                        createdAt: Date
                        updatedAt: Date
                ): Boolean
                DeleteUserCourse(
                        _id: ID
                        basicId: Int
                        userId: Int
                        liveId: Int
                        subscriptionId: ID
                        admin: ID
                        contributor: Int
                        createdAt: Date
                        updatedAt: Date
                ): Boolean
        }

        type ProgressionSetter {
                id: ID
                videoDurations: JSONObject
                setUserProgressions: Boolean
                setTotalProgressions: Boolean
                setGlobalProgression: Boolean
        }

        type Query {
                Course(
                        _id: ID
                        title: String
                        basicId: Int
                        shootingDate: String
                        status: String
                        url: String
                        live: [ID]
                        jwplaylistId: String
                ): Course
                CourseInfos(
                        _id: ID
                        title: String
                        basicId: Int
                        shootingDate: String
                        status: String
                        url: String
                        live: [ID]
                        jwplaylistId: String
                ): String
                Courses(
                        _id: ID
                        basicId: Int
                        shootingDate: String
                        status: String
                        duration: Int
                        createdAt: Date
                        updatedAt: Date
                        publishedAt: Date
                        isPublic: Int
                        jwplaylistId: String
                        sort: JSONObject
                        page: Int
                        pageLength: Int
                        filters: JSONObject
                        isTopCourse: Boolean
                        topCoursePosition: Int
                ): [Course]
                CoursesLearningOrder(
                        _id: ID
                        basicId: Int
                        shootingDate: String
                        status: String
                        duration: Int
                        createdAt: Date
                        updatedAt: Date
                        publishedAt: Date
                        isPublic: Int
                        jwplaylistId: String
                        sort: JSONObject
                        page: Int
                        pageLength: Int
                        filters: JSONObject
                        isTopCourse: Boolean
                        topCoursePosition: Int
                ): [JSONObject]
                CoursePages(offset: Int, limit: Int, sort: JSONObject): [Course]
                CourseChapter(
                        _id: ID
                        title: String
                        index: Int
                        courseId: ID
                        parentId: ID
                        typeName: String
                        ressource: [JSONObject]
                        isBonus: Boolean
                ): CourseChapter
                CourseChapters(
                        _id: ID
                        title: String
                        index: Int
                        courseId: ID
                        parentId: ID
                        typeName: String
                        ressource: [JSONObject]
                        isBonus: Boolean
                ): [CourseChapter]
                CourseChaptersByUrl(url: String): [CourseChapter]
                CourseVideo(
                        _id: ID
                        id: Int
                        title: String
                        link: String
                        duration: Int
                        mediaId: String
                        jwplayerId: String
                        index: Int
                        chapterId: ID
                        courseId: ID
                        parentId: ID
                        typeName: String
                        ressource: [JSONObject]
                        subvideos: [JSONObject]
                        downloadLink: String
                        kinescopeId: String
                        kinescopeFolderId: String
                        splits: [JSONObject]
                        additionalMaterials: [JSONObject]
                        privacyDomains: [JSONObject]
                        platform: String
                ): CourseVideo
                CourseVideos(
                        sort: JSONObject
                        _id: ID
                        id: Int
                        title: String
                        link: String
                        duration: Int
                        mediaId: String
                        jwplayerId: String
                        index: Int
                        chapterId: ID
                        courseId: ID
                        parentId: ID
                        typeName: String
                        ressource: [JSONObject]
                        subvideos: [JSONObject]
                        downloadLink: String
                        kinescopeId: String
                        kinescopeFolderId: String
                        splits: [JSONObject]
                        additionalMaterials: [JSONObject]
                        privacyDomains: [JSONObject]
                        platform: String
                ): [CourseVideo]
                FlatPlaylist(
                        sort: JSONObject
                        _id: ID
                        id: Int
                        title: String
                        link: String
                        duration: Int
                        mediaId: String
                        jwplayerId: String
                        index: Int
                        chapterId: ID
                        courseId: ID
                        parentId: ID
                        typeName: String
                        ressource: [JSONObject]
                        subvideos: [JSONObject]
                        downloadLink: String
                        kinescopeId: String
                        kinescopeFolderId: String
                        splits: [JSONObject]
                        additionalMaterials: [JSONObject]
                        privacyDomains: [JSONObject]
                        platform: String
                ): [CourseVideo]
                VideoUrlsForMobile(
                        courseId: ID
                        courseBasicId: Int
                ): [JSONObject]
                Live(_id: ID, url: String, courseId: Int, status: String): Live
                Lives(
                        _id: ID
                        url: String
                        courseId: Int
                        startDate: Date
                        endDate: Date
                        nQFreeUsers: Int
                        status: String
                        promoCode: String
                        promoAmount: Int
                        promoExp: Date
                        postFb: String
                        idYtCreatorStudio: String
                        hotjarScript: String
                        customerioScript: String
                        updatedAt: Date
                        stats: JSONObject
                        subOnly: Boolean
                        sort: JSONObject
                        isPublic: Int
                ): [Live]
                NextLive: [Live]
                KineScopeData(kinescopeId: String, basicId: Int): JSONObject
                Author(
                        _id: ID
                        basicId: Int
                        userId: ID
                        email: String
                        courseId: [ID]
                ): Author
                Authors(
                        _id: ID
                        isPublic: Int
                        courseId: [ID]
                        sort: JSONObject
                ): [Author]
                Question(_id: ID, basicId: Int): Question
                Questions(
                        userId: ID
                        liveId: ID
                        likes: Int
                        answered: Boolean
                        createdAt: Int
                        tag: String
                ): [Question]
                LiveStat(_id: ID): LiveStats
                LiveStats(courseId: ID): [LiveStats]
                Feedback(_id: ID, basicId: Int): Feedback
                Feedbacks(
                        courseId: ID
                        userId: ID
                        mark: Boolean
                        createdAt: Int
                        updatedAt: Int
                ): [Feedback]
                Field(
                        _id: ID
                        name: String
                        image: String
                        altImage: String
                        description: String
                        level: Int
                        position: Int
                        positionsList: [JSONObject]
                        children: [ID]
                        coursePositions: [JSONObject]
                ): Field
                FieldsHeader: [Field]
                Fields(
                        _id: ID
                        name: String
                        image: String
                        altImage: String
                        description: String
                        level: Int
                        position: Int
                        positionsList: [JSONObject]
                        children: [ID]
                        coursePositions: [JSONObject]
                ): [Field]
                Reservation(
                        _id: ID
                        courseId: ID
                        userId: ID
                        createdAt: Date
                        updatedOn: Date
                ): Reservation
                Reservations(
                        _id: ID
                        courseId: ID
                        userId: ID
                        createdAt: Date
                        updatedOn: Date
                ): [Reservation]
                UserVideoProgression(
                        _id: ID
                        updatedAt: Date
                ): UserVideoProgression
                UserVideoProgressions(
                        _id: ID
                        userId: ID
                        videoId: ID
                        progress: Int
                        virtualProgress: Int
                        completed: Boolean
                        timeSpent: Int
                        updatedAt: Date
                ): [UserVideoProgression]
                UserChapterProgression(_id: ID): UserChapterProgression
                UserChapterProgressions(
                        _id: ID
                        userId: ID
                        chapterId: ID
                        progress: Int
                        virtualProgress: Int
                ): [UserChapterProgression]
                UserCourseProgression(_id: ID): UserCourseProgression
                UserCourseProgressions(
                        _id: ID
                        userId: ID
                        courseId: ID
                        progress: Int
                        virtualProgress: Int
                ): [UserCourseProgression]
                GetProgression(
                        userId: ID!
                        courseId: ID!
                        restructurate: Boolean
                        videoId: ID
                        chapterId: ID
                        resetRedis: Boolean
                ): JSONObject
                Gotw(_id: ID, userId: ID, createdAt: Date): Gotw
                Gotws(
                        _id: ID
                        goal: Int
                        progress: Int
                        createdAt: Date
                        duration: Int
                ): [Gotw]
                UserProFormation(
                        _id: ID
                        userId: ID
                        courseId: ID
                        status: String
                ): UserProFormation
                UserProFormations(
                        _id: ID
                        userId: ID
                        courseId: ID
                        status: String
                ): [UserProFormation]
                GetCoursesWatchTime(
                        fromDate: String!
                        toDate: String!
                ): [JSONObject]
                GetLastSeenVideos(
                        email: String!
                        courseBasicId: Int
                        all: Boolean
                ): [JSONObject]
                Cron(_id: ID!): Cron
                Crons(
                        _id: ID
                        name: String
                        tag: String
                        running: Boolean
                        schedule: String
                        createdAt: Date
                        updatedAt: Date
                ): [Cron]
                Script(_id: ID!): Script
                Scripts(
                        _id: ID
                        name: String
                        tag: String
                        createdAt: Date
                        updatedAt: Date
                ): [Script]
                LaunchScript(_id: ID!): Boolean
                Webhook(_id: ID!): Webhook
                Webhooks(
                        _id: ID
                        type: String
                        user: ID
                        course: ID
                        byDefault: Boolean
                        createdAt: Date
                        updatedAt: Date
                ): [Webhook]
                CreateCheckoutSession(
                        customerEmail: String
                        stripeId: String
                        priceKey: String
                ): String
                CreateKlarnaSession(
                        customerEmail: String
                        stripeId: String
                        priceKey: String
                ): String
                CreateScheduleSession(
                        customerEmail: String
                        stripeId: String
                        activeSub: String
                ): String
                CreatePortalSession(sessionId: String): String
                CreatePortalSessionCustomer(
                        customerId: String
                        basicId: Int
                ): String
                User(
                        _id: ID
                        basicId: Int
                        email: String
                        username: String
                ): User
                UserList: [User]
                Users(
                        isActivated: Boolean
                        isGoogle: Boolean
                        sort: JSONObject
                        page: Int
                        pageLength: Int
                        isTemporary: Boolean
                        email: String
                ): [User]
                UserSearch(searchInput: String): [JSONObject]
                SearchUserDB(searchInput: String): [User]
                UsersPages(offset: Int, limit: Int, sort: JSONObject): [User]
                Logout: Boolean
                RecoveryUrl(email: String!): UserStatus
                RefreshToken: Token
                UserImage(
                        _id: ID
                        loginUrl: String
                        createUrl: String
                ): UserImage
                Subscription(
                        user: ID
                        subscriptionPlan: ID
                        _id: ID
                        basicId: Int
                        userId: Int
                        planId: Int
                        multiPaymentId: Int
                        planType: String
                        stripe: JSONObject
                        oneShot: Boolean
                        paypal: JSONObject
                        stripeId: String
                        mobileReceipt: String
                        status: String
                        name: String
                        isActive: Int
                        isTrial: Int
                        isUnpaid: Int
                        isPending: Int
                        isWaitingunsub: Int
                        nbFreeDownloaded: Int
                        currentPeriodStart: Date
                        currentPeriodEnd: Date
                        canceledAt: Date
                        createdAt: Date
                        endedAt: Date
                        updatedAt: Date
                        raisonResiliation: String
                        subType: String
                        platform: String
                ): Subscription
                Subscriptions(
                        _id: ID
                        basicId: Int
                        userId: Int
                        planId: Int
                        multiPaymentId: Int
                        planType: String
                        stripe: JSONObject
                        oneShot: Boolean
                        paypal: JSONObject
                        stripeId: String
                        mobileReceipt: String
                        status: String
                        name: String
                        isActive: Int
                        isTrial: Int
                        isUnpaid: Int
                        isPending: Int
                        isWaitingunsub: Int
                        nbFreeDownloaded: Int
                        currentPeriodStart: Date
                        currentPeriodEnd: Date
                        canceledAt: Date
                        createdAt: Date
                        endedAt: Date
                        updatedAt: Date
                        raisonResiliation: String
                        subType: String
                        platform: String
                ): [Subscription]
                SubscriptionPlan(
                        _id: ID
                        basicId: Int
                        stripeId: String
                        name: String
                        title: String
                        titled: String
                        shortDescription: String
                        description: String
                        paymentDescription: String
                        accessMultiPayment: Int
                        amount: Int
                        interval: String
                        trialDays: Int
                        isPublic: Int
                        hasBonus: Int
                        intervalNb: Int
                        nbFreeDownload: Int
                        couponName: String
                        order: Int
                        isTop: Int
                        pricingPage: Int
                        pricingPage2: Int
                        buttonSubscribe: String
                        buttonUpgrade: String
                        createdAt: Date
                ): SubscriptionPlan
                SubscriptionPlans(
                        _id: ID
                        basicId: Int
                        stripeId: String
                        name: String
                        title: String
                        titled: String
                        shortDescription: String
                        description: String
                        paymentDescription: String
                        accessMultiPayment: Int
                        amount: Int
                        interval: String
                        trialDays: Int
                        isPublic: Int
                        hasBonus: Int
                        intervalNb: Int
                        nbFreeDownload: Int
                        couponName: String
                        order: Int
                        isTop: Int
                        pricingPage: Int
                        pricingPage2: Int
                        buttonSubscribe: String
                        buttonUpgrade: String
                        createdAt: Date
                        basicIds: [Int]
                ): [SubscriptionPlan]
                Receipt(
                        _id: ID
                        basicId: Int
                        userId: Int
                        fullname: String
                        reference: String
                        companyName: String
                        tvaIdentification: String
                        address1: String
                        address2: String
                        zipCode: String
                        city: String
                        paidAt: Date
                        country: String
                        amount: Int
                        user: ID
                ): Receipt
                Receipts(
                        _id: ID
                        basicId: Int
                        userId: Int
                        fullname: String
                        reference: String
                        companyName: String
                        tvaIdentification: String
                        address1: String
                        address2: String
                        zipCode: String
                        city: String
                        paidAt: Date
                        country: String
                        amount: Int
                        user: ID
                ): [Receipt]
                Invoice(
                        reference: String
                        _id: ID
                        platform: String
                        stripe: JSONObject
                        paypal: JSONObject
                        alma: JSONObject
                        createdAt: Date
                        updatedAt: Date
                ): Invoice
                Invoices(
                        reference: String
                        _id: ID
                        platform: String
                        stripe: JSONObject
                        paypal: JSONObject
                        alma: JSONObject
                        createdAt: Date
                        updatedAt: Date
                        user: ID
                ): [Invoice]
                UserCourse(
                        _id: ID
                        basicId: Int
                        userId: Int
                        liveId: Int
                        subscriptionId: ID
                        admin: ID
                        contributor: Int
                        createdAt: Date
                        updatedAt: Date
                ): UserCourse
                UserCourses(
                        _id: ID
                        basicId: Int
                        userId: Int
                        liveId: Int
                        subscriptionId: ID
                        admin: ID
                        contributor: Int
                        createdAt: Date
                        updatedAt: Date
                ): [UserCourse]
                GetStripeInvoice(invoiceId: String!): JSONObject
                Watching(browserId: String): JSONObject
                BoComment(
                        _id: ID
                        adminId: ID
                        userId: ID
                        comment: String
                        createdAt: Date
                        updatedOn: Date
                ): BoComment
                BoComments(
                        _id: ID
                        adminId: ID
                        userId: ID
                        comment: String
                        createdAt: Date
                        updatedOn: Date
                ): [BoComment]
        }

        type Question {
                _id: ID
                basicId: Int
                userId: ID
                courseId: ID
                question: String
                likes: Int
                answered: Boolean
                tag: String
                createdAt: Int
        }

        type Receipt {
                _id: ID
                basicId: Int
                userId: Int
                fullname: String
                reference: String
                companyName: String
                tvaIdentification: String
                address1: String
                address2: String
                zipCode: String
                city: String
                paidAt: Date
                country: String
                amount: Int
                user: ID
        }

        type Refund {
                _id: ID
                basicId: Int
                userId: Int
                receiptId: Int
                fullname: String
                companyName: String
                address1: String
                address2: String
                zipCode: String
                city: String
                reference: String
                paidAt: Date
                country: String
                amount: Int
                receipt: ID
                user: ID
                stripe: JSONObject
                paypal: JSONObject
                platform: String
                invoice: ID
                createdAt: Date
                updatedAt: Date
        }

        type Reservation {
                _id: ID
                courseId: ID
                userId: ID
                createdAt: Date
                updatedOn: Date
                course: Course
                user: User
        }

        type Script {
                _id: ID
                name: String
                description: String
                tag: String
                query: String
                createdAt: Date
                updatedAt: Date
        }

        type Subscription {
                user: User
                subscriptionPlan: SubscriptionPlan
                _id: ID
                basicId: Int
                userId: Int
                planId: Int
                multiPaymentId: Int
                planType: String
                stripe: JSONObject
                oneShot: Boolean
                paypal: JSONObject
                stripeId: String
                mobileReceipt: String
                status: String
                name: String
                isActive: Int
                isTrial: Int
                isUnpaid: Int
                isPending: Int
                isWaitingunsub: Int
                nbFreeDownloaded: Int
                currentPeriodStart: Date
                currentPeriodEnd: Date
                canceledAt: Date
                createdAt: Date
                endedAt: Date
                updatedAt: Date
                raisonResiliation: String
                subType: String
                platform: String
        }

        type SubscriptionPlan {
                _id: ID
                basicId: Int
                stripeId: String
                name: String
                title: String
                titled: String
                shortDescription: String
                description: String
                paymentDescription: String
                accessMultiPayment: Int
                amount: Int
                interval: String
                trialDays: Int
                isPublic: Int
                hasBonus: Int
                intervalNb: Int
                nbFreeDownload: Int
                couponName: String
                order: Int
                isTop: Int
                pricingPage: Int
                pricingPage2: Int
                buttonSubscribe: String
                buttonUpgrade: String
                createdAt: Date
        }

        type Token {
                accessToken: String
                refreshToken: String
        }

        type User {
                _id: ID
                lastWatched(courseId: ID): JSONObject
                gotw: Gotw
                progression(courseId: ID): Int
                progressions: [JSONObject]
                videoProgressions(
                        courseId: ID
                        courseBasicId: Int
                ): [JSONObject]
                autoEvaluationMark(courseId: ID): Int
                trialTimeWatched: Int
                all(
                        isActivated: Boolean
                        isGoogle: Boolean
                        sort: JSONObject
                        page: Int
                        pageLength: Int
                        isTemporary: Boolean
                        email: String
                ): [User]
                basicId: Int
                email: String
                firstname: String
                lastname: String
                password: String
                refreshToken: String
                activationToken: String
                isActivated: Boolean
                username: String
                profilePicture: String
                isGoogle: Boolean
                isTemporary: Boolean
                hasTrialed: Int
                stripe: String
                rememberCard: Int
                tva: String
                companyName: String
                timezone: String
                adresse: String
                zipcode: String
                city: String
                country: String
                birthday: String
                liveLocalisation: String
                shareLocalisation: Int
                googleId: String
                facebookId: String
                unsubscribeId: String
                referrerId: Int
                referralCreation: Int
                sharingLink: String
                totalGOTW: Int
                currentGOTW: Int
                createdAt: Date
                updatedOn: Date
                lastLogin: Date
                lastVisit: Date
                invoices: [Invoice]
                receipts: [Receipt]
                refunds: [Refund]
                subscription: Subscription
                scheduledSubscription: Subscription
                subscriptions: [Subscription]
                UserCourses: [UserCourse]
                hasCourse(courseId: ID!): Boolean
                canAddCourse: Int
                addCourse(courseId: ID!): Boolean
                subType: String
                courses: [Course]
                roles: [String]
                hasBonus: Boolean
                isPro: Boolean
        }

        type UserChapterProgression {
                _id: ID
                userId: ID
                chapterId: ID
                progress: Int
                virtualProgress: Int
                completed: Boolean
        }

        type UserCourse {
                course: Course
                user: User
                _id: ID
                basicId: Int
                userId: Int
                liveId: Int
                subscriptionId: ID
                admin: ID
                contributor: Int
                createdAt: Date
                updatedAt: Date
        }

        type UserCourseProgression {
                course: Course
                _id: ID
                userId: ID
                courseId: ID
                progress: Int
                virtualProgress: Int
                completed: Boolean
                updatedAt: Date
        }

        type UserImage {
                _id: ID
                loginUrl: String
                createUrl: String
        }

        type UserProFormation {
                _id: ID
                userId: ID
                courseId: ID
                status: String
        }

        type UserStatus {
                code: Int
                message: String
        }

        type UserVideoProgression {
                _id: ID
                userId: ID
                videoId: ID
                progress: Int
                virtualProgress: Int
                completed: Boolean
                lastPosition: Int
                timeSpent: Int
                updatedAt: Date
        }

        type Webhook {
                _id: ID
                type: String
                user: ID
                course: ID
                byDefault: Boolean
                createdAt: Date
                updatedAt: Date
        }
`
