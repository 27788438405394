import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { Lazyload } from '@vant/lazyload'
import * as apolloProvider from './library/apollo.provider'
import Bugsnag from '@bugsnag/js'

import BugsnagPluginVue from '@bugsnag/plugin-vue'
// Bugsnag.start({
//     apiKey: 'c9551ca8f580d6a24c518e9eb347fcd7',
//     plugins: [new BugsnagPluginVue()],
// })
const bugsnagVue = Bugsnag.getPlugin('vue')
console.log(import.meta)
import './index.css'
// axios.defaults.withCredentials = false
axios.defaults.baseURL = import.meta.env.VITE_BACKEND_URL
axios.defaults.headers = { 'Content-Type': 'application/json' }
createApp(App)
        .use(router)
        .use(store)
        .use(Lazyload)
        .use(bugsnagVue)
        .use(apolloProvider.provider)
        .mount('#app')

if (window.Cypress) {
        // Add `store` to the window object only when testing with Cypress
        window.store = store
}
