import { createStore, createLogger } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user'
import playlists from './modules/playlists'

const store = createStore({
        modules: {
                user,
                playlists,
        },
        plugins: [
                createPersistedState({ paths: ['user.user', 'playlists'] }),
                createLogger(),
        ],
})

export default store
