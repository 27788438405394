import jwt_decode from 'jwt-decode'
import axios from 'axios'
import { LOGIN } from '../../graphql/Login.js'
import * as apolloProvider from '../../library/apollo.provider'
export default {
        state: {
                user: {
                        _id: '',
                        firstname: '',
                        email: '',
                        profilePicture: '',
                        basicId: null,
                        roles: [],
                        accessToken: '',
                },
                loginError: '',
        },
        getters: {
                user(state) {
                        return state.user
                },
                loginError(state) {
                        return state.loginError
                },
                haveToken(state) {
                        if (state?.user?.accessToken) return true
                        else return false
                },
                isAuthenticated(state) {
                        if (state?.user?.accessToken != '') {
                                const decoded = jwt_decode(
                                        state.user.accessToken
                                )
                                const expireDate = new Date(decoded.exp * 1000)
                                if (expireDate < new Date()) return false
                                return true
                        }
                        return false
                },
        },
        mutations: {
                setUser(state, user) {
                        state.user = user
                },
                loginError(state, message) {
                        state.loginError = message
                },
                LogOut(state) {
                        state.user = {
                                _id: '',
                                firstname: '',
                                email: '',
                                profilePicture: '',
                                basicId: null,
                                roles: [],
                                accessToken: '',
                        }
                },
        },
        actions: {
                async getUser({ commit }, token) {
                        try {
                                const query = `query {
                    User {
                    _id
                    firstname
                    email
                    profilePicture
                    basicId
                    roles
                    isActivated
                    }
                }`

                                const config = {
                                        headers: {
                                                Authorization: `Bearer ${token}`,
                                                'Content-Type': 'application/json'
                                        },
                                }

                                const res = await axios.post(
                                        import.meta.env.VITE_BACKEND_URL,
                                        { query },
                                        config
                                )
                                if (res.data.errors) throw res.data.errors
                                const user = res.data.data.User
                                if (!user.isActivated)
                                        throw [
                                                {
                                                        message: "Ton compte n'est pas activé.",
                                                },
                                        ]
                                user.accessToken = token
                                await commit('setUser', user)
                        } catch (err) {
                                console.log(err)
                                throw err
                        }
                },
                async Login({ dispatch }, credentials) {
                        const res =
                                await apolloProvider.provider.defaultClient.mutate(
                                        {
                                                mutation: LOGIN,
                                                variables: {
                                                        ...credentials,
                                                },
                                        }
                                )
                        if (res.data.errors) throw res.data.errors
                        const token = res?.data?.Login.accessToken
                        const decoded = jwt_decode(token)
                        if (decoded.roles.includes('admin')) {
                                await dispatch('getUser', token)
                        } else {
                                throw "Ton statut ne permet pas l'accès au Back-Office"
                        }
                },
                async LogOut({ state, commit }) {
                        console.log(commit)
                        const query = `query{Logout}`

                        const config = {
                                headers: {
                                        Authorization: `Bearer ${state.user.accessToken}`,
                                },
                        }

                        const res = await axios.post(import.meta.env.VITE_BACKEND_URL, { query }, config)
                        console.log(res)
                        if (res.data.errors) throw res.data.errors
                        let user = null
                        commit('LogOut', user)
                },
        },
}
