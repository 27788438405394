import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
function lazyLoad(view) {
        return () => import(`../components/${view}.vue`)
}
const routes = [
        {
                path: '/',
                redirect: '/catalogue',
                name: 'MainDashboard',
                component: lazyLoad('MainDashboard'),
                meta: { requiresAuth: true },
        },
        {
                path: '/login',
                name: 'LoginPage',
                component: lazyLoad('LoginPage'),
                meta: { guest: true },
        },
        {
                path: '/catalogue',
                name: 'CoursesPage',
                component: lazyLoad('CoursesPage'),
                meta: { requiresAuth: true },
        },
        {
                path: '/catalogue/createcourse/:authorId?',
                name: 'CreateCourse',
                component: lazyLoad('CreateCourse'),
                meta: { requiresAuth: true },
        },
        {
                path: '/catalogue/editcourse/:url',
                name: 'EditCourse',
                components: {
                        default: lazyLoad('EditCourse'),
                },
                meta: { requiresAuth: true },
        },
        {
                path: '/formateurs',
                name: 'AuthorsPage',
                component: lazyLoad('AuthorsPage'),
                meta: { requiresAuth: true },
        },
        {
                path: '/domaines',
                name: 'FieldsPage',
                component: lazyLoad('FieldsPage'),
                meta: { requiresAuth: true },
        },
        {
                path: '/stats',
                name: 'GlobalStats',
                component: lazyLoad('GlobalStats'),
                meta: { requiresAuth: true },
        },
        {
                path: '/gestion-catalogue',
                name: 'CatalogManagerPage',
                component: lazyLoad('CatalogManagerPage'),
                meta: { requiresAuth: true },
        },
        {
                path: '/live/:url',
                name: 'LivePage',
                component: lazyLoad('LivePage'),
                meta: { requiresAuth: true },
        },
        {
                path: '/top-courses',
                name: 'TopCourses',
                component: lazyLoad('TopCourses'),
                meta: { requiresAuth: true },
        },
        {
                path: '/upload-images',
                name: 'UploadImage',
                component: lazyLoad('UploadImage'),
                meta: { requiresAuth: true },
        },
        {
                path: '/users',
                name: 'UsersPage',
                component: lazyLoad('UsersPage'),
                meta: { requiresAuth: true },
        },
        {
                path: '/users/:basicId',
                name: 'NewUserPage',
                components: {
                        default: lazyLoad('NewUserPage'),
                },
                meta: { requiresAuth: true },
        },
        {
                path: '/successpayment/:session_id',
                name: 'SuccessPayment',
                component: lazyLoad('Payments/successPayment'),
                meta: { requiresAuth: true },
        },
        {
                path: '/bricabrac',
                name: 'BricABrac',
                component: lazyLoad('BricABrac'),
        },
        {
                path: '/momo-test',
                name: 'MomoTest',
                component: lazyLoad('MomoTest'),
        },
        {
                path: '/coupons',
                name: 'Coupons',
                component: lazyLoad('CouponsPage'),
        },
        {
                path: '/files',
                name: 'Files',
                component: lazyLoad('FilesPage'),
        },
]

const router = createRouter({
        history: createWebHistory(),
        routes,
})

router.beforeEach((to, from, next) => {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
                if (store.getters.isAuthenticated) {
                        next()
                        return
                }
                next('/login')
        } else {
                next()
        }
})

router.beforeEach((to, from, next) => {
        if (to.matched.some((record) => record.meta.guest)) {
                if (store.getters.isAuthenticated) {
                        next('/catalogue')
                        return
                }
                next()
        } else {
                next()
        }
})

export default router
