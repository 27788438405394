import {
        InMemoryCache,
        ApolloClient,
        createHttpLink,
} from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'

import { offsetLimitPagination } from '@apollo/client/utilities'

import { createApolloProvider } from '@vue/apollo-option'
import { typeDefs } from './typeDefs'

const cache = new InMemoryCache({
        typePolicies: {
                Query: {
                        fields: {
                                CoursePages: offsetLimitPagination(),
                        },
                },
        },
})

const httpLink = createHttpLink({
        uri: import.meta.env.VITE_BACKEND_URL,
        // credentials: 'include', // This is important for including cookies in the request
        
})

function getCookie(name) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
}

const authLink = setContext((_, { headers }) => {
        // Get the authentication token from the 'token' cookie
        const token = getCookie('token')

        // Return the headers to the context so httpLink can read them
        return {
                headers: {
                        ...headers,
                        authorization: token ? `Bearer ${token}` : '',
                },
        }
})

const apolloClient = new ApolloClient({
        link: authLink.concat(httpLink),
        cache,
        typeDefs,
        resolvers: {},
        defaultOptions: {
                watchQuery: {
                        fetchPolicy: 'cache-and-network',
                        nextFetchPolicy: 'cache-first',
                },
        },
})

export const provider = createApolloProvider({
        defaultClient: apolloClient,
})
